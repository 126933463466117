import React from "react"

// Libraries
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import styled from "styled-components"
import { Trans } from "gatsby-plugin-react-i18next"

// Utils
import breakpoint from "utils/breakpoints/"

// Components
import Section from "components/case-study-section/"

const ImageWrapper = styled.div`
  max-width: 1440px;
  margin: 16px auto 0 auto;
  overflow: hidden;

  ${breakpoint.medium`
    margin-top: 60px;
  `}

  .image {
    width: 95%;
    max-width: 1280px;
    display: block;
    margin-bottom: 16px;

    &:nth-child(even) {
      margin-left: auto;
    }

    ${breakpoint.medium`
      margin-bottom: 20px;

      &:last-child {
        margin-bottom: 0;
      }
    `}
  }
`

const BrandNarrative = () => {
  const data = useStaticQuery(graphql`
    query {
      imageOne: file(
        relativePath: { eq: "case-studies/kapital/brand-narrative-image-1.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 1280, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      imageTwo: file(
        relativePath: { eq: "case-studies/kapital/brand-narrative-image-2.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 1280, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)
  return (
    <Section className="bg--cream">
      <div className="container">
        <div className="section__title"></div>
        <div className="section__content">
          <p className="title">
            <Trans>1. Brand Narrative Workshop</Trans>
          </p>
          <p>
            <Trans>
              A well-defined brand is the foundation for a strong, successful
              company. We started with this workshop--which combines elements of
              a traditional Brand Sprint and a Brand Story Workshop--to get a
              feel for our client’s vision for the company, define its values,
              and better understand what problems they wanted to solve for his
              prospective users. This essential step made sure that going
              forward, all the visual and written content for Kapital was
              aligned with the brand.
            </Trans>
          </p>
        </div>
      </div>
      <ImageWrapper>
        <div className="image" data-aos="fade-right" data-aos-duration="600">
          <Img
            fluid={data.imageOne.childImageSharp.fluid}
            alt="Illustration System"
            style={{ width: "100%" }}
          />
        </div>
        <div className="image" data-aos="fade-left" data-aos-duration="600">
          <Img
            fluid={data.imageTwo.childImageSharp.fluid}
            alt="Illustration System"
            style={{ width: "100%" }}
          />
        </div>
      </ImageWrapper>
    </Section>
  )
}

export default BrandNarrative