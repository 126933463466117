import React from "react"

// Libraries
import styled from "styled-components"
import { useStaticQuery, graphql } from "gatsby"
import { Trans, useTranslation } from "gatsby-plugin-react-i18next"

// Utils
import { colors } from "utils/variables/"

// Components
import Section from "components/case-study-section/"
import Quote from "components/case-study-quote/"
import Lottie from "components/lottie-observed/"

// Animations
import Animation from "assets/animations/case-studies/kapital/the-challenge-animation.json"

const StyledQuote = styled(Quote)`
  margin-top: 48px;
`

const StyledSection = styled(Section)`
  .animation-wrapper {
    margin-top: 60px;
  }
`

const Challange = () => {
  const { t } = useTranslation()

  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "case-studies/kapital/alfredo-vargas.png" }) {
        childImageSharp {
          fixed(width: 48, height: 48, quality: 100) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
    }
  `)

  return (
    <StyledSection>
      <div className="container">
        <div className="section__title">
          <div className="sticky">
            <p>
              <Trans>The challenge</Trans>
            </p>
          </div>
        </div>

        <div className="section__content">
          <h3>
            <Trans>
              How can we create a digital bank that transforms the culture of
              cash, facilitates transactions, and offers greater financial
              autonomy for Paraguayans?
            </Trans>
          </h3>
          <br />
          <p>
            <Trans>
              Our client came to us with a goal much larger and far-reaching
              than just creating an app: Facilitating financial autonomy for all
              Paraguayans.{" "}
            </Trans>
          </p>
          <br />
          <p>
            <Trans>
              As our client explained to us from the beginning, in Paraguay as
              in many countries around the world, cash is king in transactions
              and traditional, brick and mortar banks are the big dogs of the
              financial world. There are a wealth of frustrating effects of
              this: Standing in long lines outside the bank to transfer money,
              hours on hold with customer service, and no way to keep track of
              finances without going to a physical ATM.
            </Trans>
          </p>
          <br />
          <p>
            <Trans>
              The founder of Kapital sought to change that. Wanting to
              capitalize on Paraguay’s especially large population of young
              people, who are tech-savvy by nature and recently entering the
              world of financial independence (banking world?), he came to us
              with the brilliant idea for an online platform that both
              facilitates banking and provides financial literacy. All while
              being backed by a well-known National bank to provide a sense of
              security.
            </Trans>
          </p>
          <br />
          <p>
            <Trans>
              With Kapital, you can skip the long lines and learn to grow your
              money. Sounds great, right? But we know humans are creatures of
              habit. So our question was: How can we convince people to leave
              their old bank behind and swap their cash for a sleek Kapital
              debit card?
            </Trans>
          </p>

          <StyledQuote
            quote={t(
              "“There’s no comparable solution in the market right now. I’d like Kapital to become a reality, and if it doesn’t make it to the market I would hope that there will be other initiatives that fulfill this need.”"
            )}
            author="Alfredo Vargas"
            position={t("User Tester")}
            image={data.file.childImageSharp.fixed}
            backgroundColor={colors.indiblue}
            color={colors.white}
          />

          <div className="animation-wrapper bg--cream">
            <Lottie animation={Animation} />
          </div>
        </div>
      </div>
    </StyledSection>
  )
}

export default Challange