import React from "react"
import styled from "styled-components"

// Libraries
import { Trans } from "gatsby-plugin-react-i18next"

// Utils
import breakpoint from "utils/breakpoints/"

// Components
import Section from "components/case-study-section/"

const StyledSection = styled(Section)`
  padding-top: 56px !important;
  padding-bottom: 0 !important;

  ${breakpoint.medium`
    padding-top: 120px!important;
  `}

  .image-wrapper {
    max-width: 1440px;
    display: flex;
    flex-wrap: wrap;
    margin: 0 auto;

    ${breakpoint.medium`
      flex-wrap: nowrap;
      align-items: flex-end;
      margin-top: 120px;
    `}

    .image {
      width: 100%;

      &.image--one {
        max-width: 595px;
        z-index: 4000;

        ${breakpoint.medium`
          order: 1;
          width: 40%;
          position: relative;
          left: -10%;
        `}
      }

      &.image--two {
        max-width: 888px;

        ${breakpoint.medium`
          order: 0;
          width: 62%;
        `}
      }
    }
  }
`

const Solution = () => {
  return (
    <StyledSection className="bg--cream">
      <div className="container">
        <div className="section__title">
          <div className="sticky">
            <p>
              <Trans>The solution</Trans>
            </p>
          </div>
        </div>

        <div className="section__content">
          <h3>
            <Trans>
              Building a brand and marketing website that presents Kapital’s
              value proposition as a pioneer digital bank and its commitment to
              transparency, efficiency, and financial autonomy
            </Trans>
          </h3>
          <br />
          <p>
            <Trans>
              Each client has their distinct dreams, needs, and desires--which
              means we must create a unique, tailored approach for each one.
              Here’s what we did with our client to help Kapital hit the ground
              running:
            </Trans>
          </p>
        </div>
      </div>
    </StyledSection>
  )
}

export default Solution