import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import styled from "styled-components"

// Utils
import breakpoint from "utils/breakpoints/"
import { colors } from "utils/variables/"

// Libraries
import { Trans, useTranslation } from "gatsby-plugin-react-i18next"

// Components
import Section from "components/case-study-section/"
import Carousel from "components/case-study-carousel/"
import Quote from "components/case-study-quote/"

const StyledSection = styled(Section)`
  padding-top: 56px !important;

  ${breakpoint.medium`
    padding-top: 120px!important;
  `}
`

const StyledQuote = styled(Quote)`
  margin-top: 56px;

  ${breakpoint.medium`
    margin-top: 120px;
  `}

  .quote {
    font-size: 18px;
    line-height: 26px;
  }
`

const StyledCarousel = styled(Carousel)`
  margin-top: 40px;

  ${breakpoint.medium`
    margin-top: 80px;
  `}
`

const Results = () => {
  const { t } = useTranslation()
  const data = useStaticQuery(graphql`
    query {
      maximiliano: file(
        relativePath: { eq: "case-studies/kapital/maximiliano-britez.png" }
      ) {
        childImageSharp {
          fixed(width: 48, height: 48, quality: 100) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }

      carouselImageOne: file(
        relativePath: { eq: "case-studies/kapital/the-results-image-1.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 928, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }

      carouselImageTwo: file(
        relativePath: { eq: "case-studies/kapital/the-results-image-2.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 928, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }

      carouselImageThree: file(
        relativePath: { eq: "case-studies/kapital/the-results-image-3.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 928, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }

      carouselImageFour: file(
        relativePath: { eq: "case-studies/kapital/the-results-image-4.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 928, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  return (
    <StyledSection>
      <div className="container">
        <div className="section__title">
          <div className="sticky">
            <p>
              <Trans>The results</Trans>
            </p>
          </div>
        </div>

        <div className="section__content">
          <h3>
            <Trans>
              Beautiful visual branding and solid brand messaging, applied to a
              marketing site validated by real potential users and a sprint
              report that will sell
            </Trans>
          </h3>
          <br />
          <p>
            <Trans>
              Kapital now has the building blocks to grow their business. Not
              only do they have a unique, stand-out visual identity and a
              marketing site to attract potential users, but they also have the
              tools to make a solid business pitch to the bank that will back
              Kapital, as well as other potential partners. After the design
              sprint we provided our client with a full report that included the
              main conclusions and best quotes from our user tests, which they
              can use in sales pitches that are sure to convince and convert.
            </Trans>
          </p>
          <br />
          <p>
            <Trans>
              As our client said: Kapital isn’t just an app--It’s a paradigm
              shift. It’s rethinking banking to prioritize users. It’s leaving
              the cash at home and never standing in line at the bank again.
              It’s using your money how you want, when you want. It’s a bank for
              the digital world. By partnering with us at Indi, Kapital can
              start making moves to shift the paradigm for all Paraguayans.
            </Trans>
          </p>
        </div>
      </div>

      <StyledCarousel id="resultsCarousel">
        <div data-slide className="slide align--with-title">
          <Img
            fluid={data.carouselImageOne.childImageSharp.fluid}
            alt="ACGEN"
          />
        </div>
        <div data-slide className="slide">
          <Img
            fluid={data.carouselImageTwo.childImageSharp.fluid}
            alt="ACGEN"
          />
        </div>
        <div data-slide className="slide">
          <Img
            fluid={data.carouselImageThree.childImageSharp.fluid}
            alt="ACGEN"
          />
        </div>
        <div data-slide className="slide">
          <Img
            fluid={data.carouselImageFour.childImageSharp.fluid}
            alt="ACGEN"
          />
        </div>
      </StyledCarousel>

      <div className="container">
        <StyledQuote
          className="quote"
          quote={t(
            "“Without a doubt, after these months of work, we will be able to propose the paradigm shift that Kapital hopes to introduce in the Paraguayan market with much more confidence and authority.”"
          )}
          author="Maximiliano Britez"
          position={t("Founder, Kapital")}
          image={data.maximiliano.childImageSharp.fixed}
          backgroundColor={colors.indiblue}
          color={colors.white}
        />
      </div>
    </StyledSection>
  )
}

export default Results