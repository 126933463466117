import React from "react"

// Libraries
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import styled from "styled-components"
import { Trans } from "gatsby-plugin-react-i18next"

// Utils
import breakpoint from "utils/breakpoints/"

// Components
import Section from "components/case-study-section/"

const ImageWrapper = styled.div`
  max-width: 1120px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 0 16px;
  margin: 32px auto 0 auto;
  overflow: hidden;

  ${breakpoint.small`
    padding: 0 48px;
  `}

  ${breakpoint.medium`
    margin-top: 88px;
  `}

  .image {
    width: 100%;
    display: block;
    margin-bottom: 16px;

    ${breakpoint.medium`
      width: calc((100% - 32px) / 2);
      margin: 0;

      &:last-child {
        margin-bottom: 0;
      }
    `}
  }
`

const VisualBranding = () => {
  const data = useStaticQuery(graphql`
    query {
      imageOne: file(
        relativePath: { eq: "case-studies/kapital/visual-branding-image-1.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 544, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      imageTwo: file(
        relativePath: { eq: "case-studies/kapital/visual-branding-image-2.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 544, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)
  return (
    <Section className="bg--cream" style={{ paddingTop: "0" }}>
      <div className="container">
        <div className="section__title"></div>
        <div className="section__content">
          <p className="title">
            <Trans>2. Visual Branding</Trans>
          </p>
          <p>
            <Trans>
              The next step was creating a visual identity for Kapital. This is
              where the decisions made during the Brand Narrative Workshop began
              to come into play--Specifically, the desire for Kapital to come
              across as friendly, playful, and innovative to appeal to the young
              audience we had defined for it. This is what led our designer to
              create the fresh, eye-catching color combination of mint and
              purple and the fun, dynamic logo that represents Kapital today.
            </Trans>
          </p>
        </div>
      </div>

      <ImageWrapper>
        <div className="image" data-aos="fade-right" data-aos-duration="600">
          <Img
            fluid={data.imageOne.childImageSharp.fluid}
            alt="Illustration System"
          />
        </div>

        <div className="image" data-aos="fade-left" data-aos-duration="600">
          <Img
            fluid={data.imageTwo.childImageSharp.fluid}
            alt="Illustration System"
          />
        </div>
      </ImageWrapper>
    </Section>
  )
}

export default VisualBranding