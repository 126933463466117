import React from "react"

// Utils
import { colors } from "utils/variables/"

// Libraries
import { graphql } from "gatsby"
import AOS from "aos"
import "aos/dist/aos.css"
import { useTranslation } from "gatsby-plugin-react-i18next"
// Layout
import Layout from "layouts/layout-primary"

// Components
import ContextConsumer from "components/context/"
import Seo from "components/seo/"
import { CaseStudyTopic } from "components/case-study-section/"

// Sections
import Hero from "sections/case-studies/kapital/hero"
import TheChallenge from "sections/case-studies/kapital/the-challenge"
import TheSolution from "sections/case-studies/kapital/the-solution"
import BrandNarrative from "sections/case-studies/kapital/brand-narrative"
import VisualBranding from "sections/case-studies/kapital/visual-branding"
import DesignSprint from "sections/case-studies/kapital/design-sprint"
import TheResults from "sections/case-studies/kapital/the-results"
import Cta from "components/case-study-call-to-action/"
import NextProject from "components/case-study-next-project/"
import NextProjectHero from "sections/case-studies/hpims/hero"
import { useEffect } from "react"

const HPIMS = props => {
  useEffect(() => {
    AOS.init({
      once: true,
    })
  }, [])

  const {
    openGraphImage: { file: publicURL },
  } = props.data

  const { t } = useTranslation()

  return (
    <Layout menuColor={colors.supernova}>
      <ContextConsumer>
        {({ data, set }) => {
          if (data.useYellowFooter) {
            set({ useYellowFooter: false })
          }
          if (!data.darkMenu) {
            set({ darkMenu: true })
          }
          if (data.showFooter) {
            set({ showFooter: false })
          }
          if (data.simplifiedFooter) {
            set({ simplifiedFooter: false })
          }
        }}
      </ContextConsumer>
      <Seo
        title={t("Kapital Case Study - Indicius Design Agency")}
        description={t(
          "Bringing Kapital to life. A visual branding process and Design Sprint for Paraguay’s first 100% digital bank."
        )}
        image={publicURL}
      />
      <Hero />
      <CaseStudyTopic>
        <TheChallenge />
      </CaseStudyTopic>
      <CaseStudyTopic>
        <TheSolution />
        <BrandNarrative />
        <VisualBranding />
        <DesignSprint />
      </CaseStudyTopic>
      <CaseStudyTopic>
        <TheResults />
      </CaseStudyTopic>
      <Cta
        title={t(
          "Do you have a revolutionary idea for a digital platform, app, or service that you’re ready to see come to life?"
        )}
        subtitle={t("We have our cursors at the ready.")}
      />
      <NextProject url="/work/hpims" nextProjectHero={<NextProjectHero />} />
    </Layout>
  )
}

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }

    openGraphImage: file(
      relativePath: { eq: "case-studies/kapital/featured-image.png" }
    ) {
      publicURL
    }
  }
`
export default HPIMS
