import React from "react"

// Libraries
import styled from "styled-components"
import { Trans } from "gatsby-plugin-react-i18next"

// Utils
import breakpoint from "utils/breakpoints/"

// Components
import Section from "components/case-study-section/"

// Post Its
import InTwoYears from "assets/icons/case-studies/kapital/design-sprint-icon-1.inline.svg"
import PostItOne from "assets/icons/case-studies/kapital/design-sprint-icon-2.inline.svg"
import PostItTwo from "assets/icons/case-studies/kapital/design-sprint-icon-3.inline.svg"
import PostItThree from "assets/icons/case-studies/kapital/design-sprint-icon-4.inline.svg"

const StyledSection = styled(Section)`
  padding-top: 0 !important;

  .container--big {
    max-width: 1120px;
  }

  .post-it-wrapper {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    margin-top: 24px;
    overflow-x: auto;

    ${breakpoint.medium`
      flex-wrap: nowrap;
      justify-content: space-between;
      margin-top: 40px;
      // overflow: hidden;
    `}

    ${breakpoint.large`
      margin-top: 120px;
      overflow: hidden;
    `}
  }
`

const PostItWrapper = styled.div`
  flex-shrink: 0;
  display: flex;
  justify-content: space-between;
  overflow-x: auto;

  &:first-of-type {
    margin-bottom: 8px;

    ${breakpoint.medium`
      width: 328px;
      margin: 0;

      svg {
        width: 100%;
      }
    `}
  }

  &:last-of-type {
    ${breakpoint.small`
      
      svg {
        width: calc((100% - 16px ) / 3);
      }
    `}

    ${breakpoint.medium`
      width: calc(100% - 328px - 16px);
      display: flex;
      justify-content: space-between;

      svg {
        width: calc((100% - 32px) / 3);
        height: auto;
      } 
    `}

    ${breakpoint.large`
      width: calc((100% - 328px) - 40px);
      svg {
        width: calc((100% - 60px) / 3);
      }
    `}
  }

  ${breakpoint.small`
    width: 100%;
  `}

  ${breakpoint.medium`
    width: auto;
    justify-content: center;
  `}

  svg {
    flex-shrink: 0;
    margin-right: 8px;

    &:last-child {
      margin-right: 0;
    }

    ${breakpoint.small`
      flex-shrink: 1;
    `}

    ${breakpoint.medium`
      width: 224px;
      height: auto;
    `}
  }
`

const DesignSprint = () => {
  return (
    <StyledSection className="bg--cream">
      <div className="container">
        <div className="section__title"></div>
        <div className="section__content">
          <p className="title">
            <Trans>3. Design Sprint</Trans>
          </p>
          <p>
            <Trans>
              The crowning achievement of our work with Kapital was the design
              sprint, which spanned over 2 weeks between an opening 5-day sprint
              and iteration sprint. We began the sprint with a key question: Is
              Kapital’s value proposition strong enough to convince people to
              switch from their traditional banks?
            </Trans>
            <br />
            <br />
            <Trans>
              To test our question with real potential users, we created an
              Instagram ad that linked to a marketing site, which allowed users
              to read about Kapital and sign up for a “waitlist” until the app
              officially launches. While many users were eager to see the actual
              platform, they were pleased to see that they’d receive exclusive
              benefits for being a pioneering member. By the end of the sprint,
              every tester had told us they would be disappointed if the first
              100% digital bank in Paraguay didn’t come to life.
            </Trans>
          </p>
        </div>
      </div>

      <div className="container container--big">
        <div className="post-it-wrapper">
          <PostItWrapper>
            <InTwoYears />
          </PostItWrapper>

          <PostItWrapper>
            <PostItOne />
            <PostItTwo />
            <PostItThree />
          </PostItWrapper>
        </div>
      </div>
    </StyledSection>
  )
}

export default DesignSprint